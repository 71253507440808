

<script>

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }

    export default
        {
            standardChartColors: [
                "#3366cc",
                "#dc3912",
                "#ff9900",
                "#109618",
                "#990099",
                "#0099c6",
                "#dd4477",
                "#66aa00",
                "#b82e2e",
                "#316395",
                "#994499",
                "#22aa99",
                "#aaaa11",
                "#6633cc",
                "#e67300",
                "#8b0707",
                "#651067",
                "#329262",
                "#5574a6",
                "#3b3eac",
                "#b77322",
                "#16d620",
                "#b91383",
                "#f4359e",
                "#9c5935",
                "#a9c413",
                "#2a778d",
                "#668d1c",
                "#bea413",
                "#0c5922",
                "#743411"],

            pusharray: function (array, arrayToAdd) {
                for (var i = 0; i < arrayToAdd.length; i++) {
                    array.push(arrayToAdd[i]);
                }
            },
            copyElementContents: function (el) {
                var body = document.body, range, sel;
                if (document.createRange && window.getSelection) {
                    range = document.createRange();
                    sel = window.getSelection();
                    sel.removeAllRanges();
                    try {
                        range.selectNodeContents(el);
                        sel.addRange(range);
                    } catch (e) {
                        range.selectNode(el);
                        sel.addRange(range);
                    }
                } else if (body.createTextRange) {
                    range = body.createTextRange();
                    range.moveToElementText(el);
                    range.select();
                }

                document.execCommand("Copy");
            },
            copyArrayToClipboard: function (arrOfArr) {
                var thead;
                var tr;
                var table;
                var th;
                var td;
                var tbody;
                table = document.createElement("table");
                thead = document.createElement("thead");
                table.appendChild(thead);
                tr = document.createElement("tr");
                thead.appendChild(tr);
                for (var c = 0; c < arrOfArr[0].length; c++) {
                    th = document.createElement("th");
                    tr.appendChild(th);
                    th.appendChild(document.createTextNode(arrOfArr[0][c]));
                }
                tbody = document.createElement("tbody");
                table.appendChild(tbody);
                for (var r = 1; r < arrOfArr.length; r++) {
                    tr = document.createElement("tr");
                    tbody.appendChild(tr);
                    for (var d = 0; d < arrOfArr[r].length; d++) {
                        td = document.createElement("td");
                        tr.appendChild(td);
                        td.appendChild(document.createTextNode(arrOfArr[r][d]));
                    }
                }
                document.body.appendChild(table);
                this.copyElementContents(table);
                document.body.removeChild(table);

            },
            mergeArrays: function (arrayOfArray) {
                var result = [];
                for (var i = 0; i < arrayOfArray.length; i++) {
                    this.pusharray(result, arrayOfArray[i]);
                }
                return result;
            },
            copyImageToClipboard: function (imageURI) {
                var div = document.createElement("div");
                var image = document.createElement("img");
                image.src = imageURI;
                div.appendChild(image);
                div.contentEditable = true;
                document.body.appendChild(div);
                this.copyElementContents(div);
                document.body.removeChild(div);
            },
            removeStylingColumns: function (data) {
                return data.map(
                    function (arr) {
                        return arr.filter(function (v, i) {
                            return typeof (data[0][i]) === "string"
                        })
                    }
                );
            },
            exists: function (arr, search) {
                return arr.some(function (row) { return row.includes(search); });
            },
            distinct: function (value, index, self) {
                return self.indexOf(value) === index;
            },
            makeblob: function (base64, contentType) {
                var byteCharacters = window.atob(base64);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);

                var blob = new Blob([byteArray], { type: contentType });
                return blob;
            },
            downloadFile: function (contentType, filename, data) {

                if (window.navigator.msSaveBlob) {
                    var blob = this.makeblob(data, contentType);
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var a = document.createElement("a");
                    a.href = "data:" + contentType + ";base64," + data;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                }
            }
        }
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
