<template>
    <div id="MainApp">
        <div>
            <header-control v-if="UserDetails !== null" v-bind:user-details="UserDetails" />
        </div>
        <div>
            <navigation-control v-if="UserDetails !== null" v-bind:user-details="UserDetails" />
        </div>
    </div>
</template>

<script>
    import HeaderControl from './components/Header.vue'
    import NavigationControl from './components/NavigationControl.vue'
    import $ from 'jquery'

    export default {
        name: 'MainApp',
        components: {
            NavigationControl,
            HeaderControl
        },
        data: function () {
            return {
                UserDetails: null,
            };
        },
        created: function () {
            var thisControl = this;
            $.ajax({
                type: 'GET',
                url: 'Api/UserDetails',
                contentType: 'application/json',
                success: function (UserDetails) {
                    thisControl.UserDetails = UserDetails;
                    thisControl.$gtag.set({
                        'user_properties': {
                            'Name': thisControl.UserDetails.displayName,
                            'Network': thisControl.UserDetails.supportNetwork,
                            'Firm': thisControl.UserDetails.organisationName
                        }
                    });
                },
                error: function (xhr) {
                    if (xhr.status == 401) {
                        window.location = "./Api/signin";
                        return;
                    }
                    thisControl.$gtag.exception({
                        'description': 'Error getting user details (' + xhr.status + ').',
                        'fatal': true
                    });
                    //alert("Error User Details: " + xhr.responseText);
                }
            });
        }
    }
</script>
