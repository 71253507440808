<template>
    <div>
        <div class="header" style="background:none">
            <h1 class="Title">Terms & Conditions</h1>
            <div class="Options">
                <button v-on:click.stop="$emit('back')" class="button150">Close</button>
            </div>
        </div>
        <fieldset>
            <p>Welcome to Benefits Guru Pro, a research tool which compares workplace benefits products based on their features and functionality, rather than on price. These Terms of Use are designed to explain our obligations as a service provider and Your obligations as a customer. Please read them carefully.</p>
            <p>These terms are binding on any use of the Service and apply to You from the time that we switch on your access to the Service.</p>
            <p>By registering to use this Service you acknowledge that you have read and understood these Terms and have the authority to act on behalf of any person for whom You are using the Service. You are deemed to have agreed to these Terms on behalf of any entity for whom you use the Service.</p>
            <p>Agreement:</p>
            <p>1.	We are Financial Technology Research Centre Limited incorporated and registered in England and Wales with company number 02484495 whose registered office is at Finsgate, 5-7 Cranwood Street, London, EC1V 9EE (�we�, �us�, �our�).</p>
            <p>2.	You are a HR, pension/benefits specialist, financial adviser or product provider and provide financial and other advice to the general public. If you are a financial adviser you distribute the products and services of Product Providers and Insurers whose products and services (�Products and Services�) are described on our Quality Analyser website, (�Quality Analyser�).</p>
            <p>3.	Data about Product Providers, Insurers and their Products and Services is entered into Benefits Guru Pro  by us and Product Providers. We provide tools in Benefits Guru Pro which allow you to undertake research and produce reports to assist you in deciding about the suitability and appropriateness of Products and Services for your customers.</p>
            <p>4.	This agreement sets out the terms on which we will allow you to use Benefits Guru Pro.</p>
            <p>5.	You may access Benefits Guru Pro and produce comparison reports from it about Products and Services but only within the terms of this Agreement.</p>
            <p>6.	You must keep your login credentials for Benefits Guru Pro secret and confidential. We will not be liable for misuse of your login credentials unless we have caused them to become public.</p>
            <p>7.	You will ensure your employees, contractors and agents do not use Benefits Guru Pro  for any purpose other than to produce reports about Products and Services for use with your retail customers. You are not allowed to use Benefits Guru Pro for other any other purpose.</p>
            <p>8.	You will ensure you do not enter any information for any purpose contrary to law and/or regulation which includes but is not limited to uploading viruses, interfering with users or other persons using Benefits Guru Pro , ensuring you do not enter pornographic, racist or comments that incite religious hatred or violence through use of Benefits Guru Pro .</p>
            <p>9.	The basic version of Benefits Guru Pro is currently provided free of charge . For access to any other versions of Benefits Guru Pro  You will pay the Fees set out in the Order Forms. We may amend our Fees by giving you three months� notice in writing. If you do not want to pay a fee increase you may terminate this Agreement by giving us one month�s notice in writing.</p>
            <p>10.	You may purchase additional services and products from us apart from those set out in Order Forms, if you do so we will provide you with a written quote ahead of providing the services. You will accept additional services by countersigning and sending back to us the written quote we have sent to you.</p>
            <p>11.	We will provide Services to you with reasonable care and skill. If we cannot perform the Services by the date we agree with you (the �Original Performance Date�), we will agree with you a new date and time for delivery.</p>
            <p>12.	You will use your skill and judgement and your best endeavours to ensure that data you enter into and reports you create using Benefits Guru Pro  for your customers are not accurate and not misleading.</p>
            <p>13.	We may reuse data entered into Benefits Guru Pro that is not identified by you as confidential.</p>
            <p>14.	We try to provide Benefits Guru Pro  continuously but there may be times when it is unavailable for planned and unplanned maintenance and service. We will use all reasonable endeavours to resolve any maintenance and service issues as soon as possible.</p>
            <p>15.	If you request that we carry out extra work or provide additional services not set out in an Order Form, we will charge you at our usual hourly rates.</p>
            <p>16.	We may terminate this Agreement immediately upon written notice to you upon the occurrence of a material breach of this Agreement (and if such breach is capable of remedy, which has not been remedied within 30 days of written notice to the party of the breach).</p>
            <p>17.	You may terminate this Agreement by giving us up to 90 days written notice such notice to take effect on the next anniversary of the Commencement Date. Agreement.</p>
            <p>18.	Subject to Clause 17, and after the Term, this Agreement will continue for a subsequent term of 12 months, unless we agree otherwise.</p>
            <p>19.	Neither you nor we exclude or limit liability to the other for:</p>
            <p>a.	fraud or fraudulent misrepresentation;</p>
            <p>b.	death or personal injury caused by negligence;</p>
            <p>c.	a breach of any obligations implied by section 12 of the Sale of Goods Act 1979 or section 2 of the Supply of Goods and Services Act 1982; or</p>
            <p>d.	any matter in respect of which it would be unlawful for the parties to exclude liability for respectively.</p>
            <p>20.	Subject to clause 19, we shall not in any circumstances be liable whether in contract (by way of indemnity or otherwise), tort (including for negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, for:</p>
            <p>a.	any consequential or indirect loss or damage; or</p>
            <p>b.	loss of profits, business, business opportunities, revenue, turnover, reputation, goodwill, loss or corruption of data or information, anticipated savings or wasted expenditure (including management time) (all however so arising).</p>
            <p>21.	Subject to clause 19, our total aggregate liability in contract (by way of indemnity or otherwise), tort (including negligence and breach of statutory duty howsoever arising), misrepresentation (whether innocent or negligent), restitution or otherwise, arising in connection with the performance or contemplated performance of this Agreement or any collateral contract shall in all circumstances be limited to the aggregate licence fees paid by you to us in a year.</p>
            <p>22.	In performing its duties under this Agreement, each party will comply with all applicable anti-bribery and anti-corruption laws and Applicable Requirements. In particular, each party hereby acknowledges and agrees:</p>
            <p>a.	to comply with the UK Bribery Act 2010 and all anti-money laundering legislation and Applicable Requirements and that it shall not engage in any activity, practice or conduct which could be construed as a violation of said legislation and Applicable Requirements, including but not limited to offering a bribe or making a facilitation payment to any person or being involved in laundering the proceeds of crime;</p>
            <p>b.	that it will use its reasonable endeavours to ensure that its activities in connection with or relating to its obligations under this Agreement will put the other party in breach of said legislation and Applicable Requirements;</p>
            <p>c.	that it will ensure that it has and maintains throughout the duration of this Agreement appropriate internal policies and procedures within its organisation to prevent money laundering, bribery by its workforce and other people under its control.</p>
            <p>23.	Each party undertakes that to the best of its knowledge and belief having conducted reasonable investigations, none of its, agents, sub-agents, employees and sub-contractors have done any act or thing in connection with, or relating to, its obligations under or in connection with this Agreement that would violate any anti-money laundering, anti-bribery or anti-corruption laws.</p>
            <p>24.	Each party undertakes that it will comply with all relevant legislation relating to the prevention of money laundering and terrorist financing, including, but not limited to, the UK Money Laundering Regulations 2007.</p>
            <p>25.	Both parties agree to act in accordance with and comply with the provisions of the Data Protection Act 1998 (the "Act") and EU Regulation 2016/679 the General Data Protection Regulation (�GDPR�) as amended from time to time.</p>
            <p>26.	Both parties warrant that they have acquired and will continue to acquire Personal Data (as defined in the Act) of customers lawfully and fairly and have the rights to process them and transfer them to each other.</p>
            <p>27.	Both parties warrant and undertake that they have and will at all times have whilst this Agreement is in force appropriate technical and organisational measures in place to protect Personal Data against unauthorised or unlawful processing and against accidental loss or destruction of, or damage to, personal data and that they have taken all reasonable steps to ensure the reliability of any of their staff which will have access to personal data.</p>
            <p>28.	A person who is not a party to this Agreement may not enforce any of its terms under the Contracts (Rights of Third parties) Act 1999.</p>
            <p>29.	Neither You nor We may assign any of the rights under this Agreement or this Agreement itself without the prior written consent of the other party.</p>
            <p>30.	The exclusions and limitations in this Agreement apply only to the extent that they are permitted by law.</p>
            <p>31.	This Agreement and the Order Forms constitute the whole and entire agreement between you and us.</p>
            <p>32.	The Agreement is governed by the laws and England and Wales and you and we both submit to the exclusive jurisdiction of the English Courts in respect of all disputes that arise between you and us.</p>
        </fieldset>
    </div>
</template>

<script>
    export default {
        name: 'TermsConditions',
        methods: {
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
