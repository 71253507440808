<template>
    <div class="modalBackground">
        <div class="Loading">
            <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px"
                 viewBox="0 0 100 100">
                <circle fill="#9325b2" stroke="none" cx="25" cy="50" r="5">
                    <animateTransform attributeName="transform"
                                      dur="2s"
                                      type="translate"
                                      values="0 0 ; 0 -30; 0 0; 0 0; 0 0"
                                      repeatCount="indefinite" 
                                      begin="0.0" />
                </circle>
                <circle fill="#9325b2" stroke="none" cx="50" cy="50" r="5">
                    
                    <animateTransform attributeName="transform" 
                                      dur="2s" 
                                      type="translate" 
                                      values="0 0 ; 0 0; 0 -30; 0 0; 0 0" 
                                      repeatCount="indefinite" 
                                      begin="0.0" />
                </circle>
                <circle fill="#9325b2" stroke="none" cx="75" cy="50" r="5">
                    
                    <animateTransform attributeName="transform" 
                                      dur="2s" 
                                      type="translate" 
                                      values="0 0 ; 0 0; 0 0; 0 -30; 0 0" 
                                      repeatCount="indefinite" 
                                      begin="0.0" />
                </circle>
            </svg>
            <div id="loadingMessages">
                
                <p>{{LoadingMessage}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingControl',
        props: ["LoadingMessage"]
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
