<template>
    <div class="header">
        <a href="https://benefitsguru.co.uk/"><img src="/Images/BenefitsGuruProLogo.svg" style="max-width:100%;" /></a>
        <div class="Options">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderControl',
        props: ["UserDetails", "CurrentScreen"],
        methods: {
            Logout: function () {
                window.location = "/signout";
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
