<template>
    <div style="max-width:500px;">
        <div :style="'width:100%;position:relative;padding:0;padding-bottom:' + (100 * (ChartData.length * BarArea + TopArea) / ChartWidth ) + '%'">
            <svg style="width:100%;height:100%;position:absolute; top:0; left:0" :viewBox="'0 0 ' + ChartWidth + ' ' + (ChartData.length * BarArea + TopArea)">
                <g v-for="(marking, index) in Markings" :key="index">
                    <text text-anchor="middle"
                          :x="index / (Markings.length - 1) * (ChartWidth - LeftArea - RightArea) + LeftArea"
                          :y="TopArea-TextGap"
                          font-family="Roboto"
                          :font-size="ScoreLegendFontSize"
                          fill="#777777">{{marking}}</text>
                    <line v-if="index > 0"
                          :x1="index / (Markings.length - 1) * (ChartWidth - LeftArea - RightArea) + LeftArea"
                          :x2="index / (Markings.length - 1) * (ChartWidth - LeftArea - RightArea) + LeftArea"
                          :y1="TopArea"
                          :y2="TopArea + BarArea * ChartData.length"
                          stroke="#aaaaaa"
                          stroke-width="0.5" />
                </g>
                <g v-for="(dataItem, index) in ChartData" :key="index">
                    <text text-anchor="start"
                          dominant-baseline="middle"
                          :x="LeftArea+TextGap"
                          :y="index * BarArea + TopArea + 0.5 * (BarArea - GapBetweenTextAndBar - BarHeight)"
                          font-family="Roboto"
                          :font-size="ScoreLegendFontSize"
                          fill="#222222">
                        {{ dataItem.Label}}
                    </text>
                    <rect :x="LeftArea"
                          :y="index * BarArea + TopArea + 0.5 * (BarArea - GapBetweenTextAndBar - TextHeight - BarHeight) + GapBetweenTextAndBar + TextHeight"
                          :height="BarHeight"
                          stroke="none"
                          :fill="GetColor(dataItem, index)"
                          :width="dataItem.Value/100.0*(ChartWidth - LeftArea - RightArea)" />
                    <text text-anchor="start"
                          dominant-baseline="middle"
                          :x="LeftArea + dataItem.Value/100.0*(ChartWidth - LeftArea - RightArea) + TextGap"
                          :y="index * BarArea + TopArea + 0.5 * (BarArea - GapBetweenTextAndBar - TextHeight) + GapBetweenTextAndBar + TextHeight"
                          font-family="Arial"
                          :font-size="ValueFontSize"
                          fill="#222222">
                        {{ dataItem.Value}}
                    </text>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>

    import helper from './HelperMethods.vue';

    export default {
        name: 'BarChart',
        props: ["ChartData"],
        data: function () {
            return {
                Markings: ['0', '25', '50', '75', '100'],
                ChartWidth: 500.0,
                BarHeight: 10.0,
                GapBetweenTextAndBar: 2.0,
                TextHeight: 10.0,
                ProviderLegendFontSize: 12,
                ScoreLegendFontSize: 12,
                ValueFontSize: 11,
                BarArea: 33.0,
                LeftArea: 10.0,
                TopArea: 50.0,
                RightArea: 28.0,
                TextGap: 5.0,
            }
        },
        methods: {
            GetColor: function (dataItem, index) {
                if (dataItem.Color !== null && dataItem.Color !== "" && typeof (dataItem.Color) !== "undefined")
                    return dataItem.Color;
                return helper.standardChartColors[index % helper.standardChartColors.length]
            }
        },
        created: function () {

        },
        computed: {

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
