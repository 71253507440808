<template>
    <div class="footer">
        <div class="">
            <p>
                <a>
                    An&nbsp;
                    <a href="https://ftrc.co">
                        <img src="/Images/FTRC-bubbles-and-no-text-and-a-transparent-background-300x75.png" alt="" width="88" height="22">
                    </a> website
                </a>
                <a style="cursor:pointer;" v-on:click.stop="callPolicyTerms('Privacy');">Privacy Policy</a>
                <a style="cursor:pointer;" v-on:click.stop="callPolicyTerms('Terms');">Terms &amp; Conditions</a>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterControl',
        props: ["UserDetails", "CurrentScreen"],
        data: function () {
            return {

            }
        },
        methods:
        {
            callPolicyTerms: function (type) {
                if (type === 'Privacy')
                    this.$emit('privacy');
                else
                    this.$emit('tac');
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
