<template>
    <div>
        <fieldset>
            <legend>
                Analysis
            </legend>
            <div v-if="Analysis === null">
                <loading-control :loading-message="'Performing Analysis'" />
            </div>
            <div class="analisysBody" v-if="Analysis !== null && IsLoading == false">
                <p>Use the dropdown below to view the overall graph and the graphs for your chosen features.<p>
                <p>These will only show your chosen pension provider and all other providers will be anonymised.<p>
                <p>Please contact a member of the Benefits Guru team to find out how to access more detailed analysis.<p>
                    <select v-model="SelectionAnalysisOption">
                        <option v-for="option in OptionsInAnalysis" v-bind:key="option.optionValue" v-bind:value="option.optionValue">{{option.optionText}}</option>
                    </select>
                    <div align="center">
                        <span>
                            <bar-chart :chart-data="MainChartData" />
                        </span>
                    </div>
                    <div align="center" v-if="HasWeightChart">
                        <span>
                            <pie-chart :Title="WeightChartTitle" :chart-data="WeightChartData" />
                        </span>
                    </div>
            </div>
            <fieldset class="navigation analysisbottomMargin50">

                <button v-on:click.stop="$emit('back')">Back</button>
                <button v-if="Analysis !== null && IsLoading == false" v-on:click.stop="IsRequestingReport=true" style="display:none;">Download Report</button>
            </fieldset>
            <report-details v-if="IsRequestingReport===true" :user-selection="UserSelection" :user-details="UserDetails" v-on:generate-report="DownloadPDFReport" v-on:report-details-cancelled="IsRequestingReport = false" :current-analysis="Analysis" />
            <div v-if="IsLoading">

                <loading-control :loading-message="'Generating Report'" />
            </div>
        </fieldset>
    </div>
</template>

<script>
    import BarChart from './BarChart.vue';
    import PieChart from './PieChart.vue';
    import ReportDetails from './ReportDetails.vue';
    import LoadingControl from './LoadingControl.vue';
    import $ from 'jquery';
    import helper from './HelperMethods.vue';

    var mainGoogleChartReference = null;

    export default {
        name: 'Analysis',
        components: { BarChart, PieChart, ReportDetails, LoadingControl },
        props: ["UserSelection", "UserDetails"],
        data: function () {
            return {
                Analysis: null,
                Error: null,
                SelectionAnalysisOption: JSON.stringify({ title: "Analysis", feature: null }),
                IsRequestingReport: false,
                IsLoading: false
            };
        },
        methods: {
            DownloadHTMLReport: function () {
                var analysisUrl = "/report/html";
                var top3Providers = this.Analysis.providers.filter(function (p, i) { return i < 3; }).map(function (p) { return p.providerReference; });
                var request = { selection: this.UserSelection, analysis: this.Analysis, firstInsuredName: "Joe Bloggs", secondInsuredName: "Jane Bloggs", providersInDataTables: top3Providers };
                var thisControl = this;
                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (html) {
                        var newWin = open('', 'Report', 'height=300,width=300');
                        newWin.document.write(html);

                        thisControl.$gtag.event('Download', {
                            'event_category': thisControl.UserSelection.tool.toolName,
                            'event_label': request.providersInDataTables != null && request.selection.tool.hasDataTables ? "with datatables" : "without datatables",
                        });
                    },
                    error: function (xhr)
                    {
                        thisControl.$gtag.exception({
                            'description': 'Error getting Analysis details (' + xhr.status + ').',
                            'fatal': true
                        });
                        //alert("Problem Performing Analysis" + xhr.responseText);
                    }
                });
            },
            DownloadPDFReport: function (request) {
                var analysisUrl = "/report/pdf";
                var thisControl = this;
                this.IsLoading = true;
                this.IsRequestingReport = false;
                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (pdf) {
                        helper.downloadFile("application./pdf", "Report.pdf", pdf);
                        thisControl.IsLoading = false;

                        thisControl.$gtag.event('Download', {
                            'event_category': thisControl.UserSelection.tool.toolName,
                            'event_label': request.providersInDataTables != null && request.selection.tool.hasDataTables ? "with datatables" : "without datatables",
                        });
                    },
                    error: function (xhr)
                    {
                        thisControl.IsLoading = false;
                        thisControl.$gtag.exception({
                            'description': 'Error getting Analysis details (' + xhr.status + ').',
                            'fatal': true
                        });

                        //alert("Problem Getting Report" + xhr.responseText);
                    }
                });
            },
            DownloadMainChartCSV: function () {
                var data = this.MainChartData;
                data = JSON.parse(JSON.stringify(data));

                data = data.map(function (arr) {
                    arr.pop();
                    return arr.join(",");
                }
                ).join("\n");
                var filename = this.MainChartOptions.title + ".csv";
                var blob = new Blob([data], { type: 'text/csv' });
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else {
                    var elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = filename;
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                }
                return false;

            },
            CopyMainChartImage: function () {
                var url = mainGoogleChartReference.getImageURI();
                helper.copyImageToClipboard(url);
            },
            CopyMainChart: function () {
                var data = this.MainChartData;
                data = JSON.parse(JSON.stringify(data));
                data = data.map(function (arr) {
                    arr.pop();
                    return arr;

                });
                helper.copyArrayToClipboard(data);
            },
            PerformAnalysis: function () {
                if (!Array.isArray(this.UserSelection.selectedProviders)) {
                    var tempSelectedProviders = [];
                    tempSelectedProviders.push(this.UserSelection.selectedProviders);
                    this.UserSelection.selectedProviders = tempSelectedProviders;
                }

                var analysisUrl = "Api/";
                var request = this.UserSelection;
                var thisControl = this;

                $.ajax({
                    type: 'POST',
                    url: analysisUrl,
                    contentType: 'application/json',
                    data: JSON.stringify(request),
                    success: function (analysis) {
                        thisControl.SelectedSubFeatureInAnalysis = "";
                        thisControl.Analysis = analysis;
                    },
                    error: function (xhr) {
                        //alert("Problem Performing Analysis" + xhr.responseText);
                        thisControl.$gtag.exception({
                            'description': 'Error getting Analysis details (' + xhr.status + ').',
                            'fatal': false
                        });
                    }
                });

            }
        },
        created: function () {
            this.PerformAnalysis();
        },
        computed: {
            OptionsInAnalysis: function () {
                var thisControl = this;
                var result = thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; })
                    .map(function (extendedFeature) {
                        var featureName = thisControl.UserSelection.extendedFeatures.filter(function (ef) { return ef.featureReference === extendedFeature.featureReference; })[0].featureName;
                        return {
                            optionValue: JSON.stringify({ title: featureName, feature: extendedFeature.featureReference }),
                            optionText: "\u00a0\u00a0\u00a0- " + featureName
                        };
                    })
                    .sort(function (a, b) {
                        if (a.optionText < b.optionText)
                            return -1;
                        if (a.optionText > b.optionText)
                            return 1;
                        return 0;
                    });

                result.unshift({
                    optionValue: JSON.stringify({ title: "Analysis", feature: null }),
                    optionText: "Overall Analysis"
                });

                return result;
            },
            MainChartData: function () {
                var selection = JSON.parse(this.SelectionAnalysisOption);
                var base = this.Analysis;
                if (selection !== null) {
                    if (selection.feature !== null)
                        base = base.featureAnalysis[selection.feature];
                }
                //var data = this.Analysis.providers.map(function (provider)
                //{
                //    return [provider.providerName, parseFloat(base.providerScores[provider.providerReference]).toFixed(2) * 1, provider.providerColor];
                //});
                //data = data.sort(function (a, b) { return b[1] - a[1]; });
                var data = this.Analysis.providers.map(function (provider) {
                    return {
                        Label: provider.providerName,
                        Value: parseFloat(base.providerScores[provider.providerReference]).toFixed(2) * 1,
                        Color: provider.providerColor
                    }
                });
                data = data.sort(function (a, b) { return b.Value - a.Value; });
                //data.unshift(['Provider', selection.title, { role: 'style' }]);
                return data;
            },
            WeightChartTitle: function () {
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection !== null) {
                    return "Feature Breakdown";
                }
                return "Category Breakdown";
            },
            HasWeightChart: function () {
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection !== null) {
                    if (selection.feature !== null) {
                        var feature = this.Analysis.featureAnalysis[selection.feature];
                        return (typeof feature.subFeatureAnalysis) !== "undefined" && feature.subFeatureAnalysis !== null && feature.subFeatureAnalysis.length > 0;
                    }
                    return true; //analysis always has a weight graph

                }
                return true; //overall always has a weight graph
            },
            WeightChartData: function () {
                var data;
                var thisControl = this;
                var selection = JSON.parse(this.SelectionAnalysisOption);
                if (selection !== null) {
                    if (selection.feature !== null) {
                        var feature = thisControl.Analysis.featureAnalysis[selection.feature];
                        data = feature.subFeatureAnalysis.map(function (subfeature) {
                            return {
                                Legend: subfeature.subFeatureName,
                                Value: subfeature.weightingPercentage.toFixed(2) * 1
                            }
                        });
                    }
                    else {
                        data = thisControl.UserSelection.extendedFeatures
                            .filter(function (feature) {
                                return thisControl.Analysis.featureAnalysis[feature.featureReference] !== undefined;
                            }).map(function (f) {
                                return {
                                    Legend: (thisControl.Analysis.featureAnalysis[f.featureReference].featureWeight.toFixed(2) * 1) + "% - " + f.featureName,
                                    Value: thisControl.Analysis.featureAnalysis[f.featureReference].featureWeight
                                };
                            });
                    }
                }
                data = data.sort(function (a, b) { return b.Value - a.Value; });
                return data;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
