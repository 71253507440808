<template>
    <div class="ModalBackground" style="display:block">
        <div class="ModalBackGroundPanel">
            <div class="header" style="background:none;">
                <h1 class="Title">Generate Report</h1>
                <div class="Options">
                    <button v-on:click.stop="$emit('report-details-cancelled')" class="button150">Cancel</button>
                </div>
                <p>
                    <label for="PolicyTerm">First Person Name: </label>
                    <input v-model="Person1" />
                </p>
                <p v-if="this.UserSelection.secondaryClient !== null">
                    <label for="PolicyTerm">Second Person Name: </label>
                    <input v-model="Person2" />
                </p>
                <p>
                    <label>
                        <input v-model="HasDataTables" type="checkbox" />
                        Include Data Tables
                    </label>
                </p>
                <p v-if="this.HasDataTables">
                    <label>Providers for Data Table</label>
                    <br />
                    <span v-for="provider in IncludedProviders" :key="provider.providerReference">
                        <select @change="ChangeProvider(provider, $event)">
                            <option v-for="ap in AvailableProviders(provider)" :key="ap.providerReference" :selected="ap.providerReference === provider" :value="ap.providerReference">{{ap.providerName}}</option>
                        </select>
                        <a href="#" v-on:click.stop="RemoveIncludedProvider(provider)">Remove</a><br />
                    </span>
                    <select v-if="IncludedProviders.length < 3" @change="AddProvider($event)">
                        <option>Select Provider To Include in Data Tables</option>
                        <option v-for="ap in AvailableProviders('')" :key="ap.providerReference" :value="ap.providerReference">{{ap.providerName}}</option>
                    </select>
                </p>
                <button v-on:click.stop="$emit('generate-report', ReportDetails())" :disabled="!CanGenerate()" class="button150">Generate Report</button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ReportDetails',
        props: ["UserSelection", "UserDetails", "CurrentAnalysis"],
        data: function () {
            return {
                Person1: "",
                Person2: "",
                HasDataTables: false,
                IncludedProviders: []
            }
        },
        methods: {
            RemoveIncludedProvider: function (provider) {
                this.IncludedProviders.splice(this.IncludedProviders.indexOf(provider), 1);
            },
            CanGenerate: function () {
                if (this.Person1.trim() === "")
                    return false;
                if (this.Person2.trim() === "" && this.UserSelection.secondaryClient !== null)
                    return false;
                if (this.HasDataTables && this.IncludedProviders.length === 0)
                    return false;
                return true;
            },
            ChangeProvider: function (oldProvider, ev) {
                this.IncludedProviders.splice(this.IncludedProviders.indexOf(oldProvider), 1, ev.target.options[ev.target.selectedIndex].value)
            },
            AddProvider: function (ev) {
                if (ev.target.selectedIndex !== 0) {
                    this.IncludedProviders.push(ev.target.options[ev.target.selectedIndex].value);
                    ev.target.selectedIndex = 0;
                }
            },
            AvailableProviders: function (providerReferenceToInclude) {
                var thisControl = this;
                return this.CurrentAnalysis.providers.filter(function (p) {
                    return thisControl.IncludedProviders.indexOf(p.providerReference) === -1 || p.providerReference === providerReferenceToInclude
                });
            },
            ReportDetails: function () {
                return {
                    selection: this.UserSelection,
                    analysis: this.CurrentAnalysis,
                    firstInsuredName: this.Person1,
                    secondInsuredName: this.UserSelection.secondaryClient === null ? null : this.Person2,
                    providersInDataTables: this.HasDataTables ? this.IncludedProviders : null
                };
            }
        },
        created: function () {
        },
        computed: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
