<template>
    <tr>
        <td v-bind:title="GetToolTip(ExtendedFeature)">
            <label>{{ExtendedFeature.featureName}}</label>
            <br v-if="AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && ExtendedFeature.subFeatures !== null" />
            <div v-if="AnalysisType === 'Comparison' && ExtendedFeature.weight > 0 && !ExtendedFeature.canWeightSubfeaturesUsingIncidenceData && ExtendedFeature.subFeatures !== null && ExtendedFeature.subFeatures.length > 1">
                <button class="button160" v-on:click.stop="SetEqualWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Prioritise Sub features Equally' ? 'button' : 'button btnNonHighlighted'">Prioritise Sub features Equally</button>
                <button class="button160" v-on:click.stop="SetCustomWeightings(ExtendedFeature);" v-bind:class="ExtendedFeature.subFeatureWeightModel == 'Custom Sub features Priority' ? 'button' : 'button btnNonHighlighted'">Custom Sub features Priority</button>
            </div>
        </td>
        <td v-if="AnalysisType === 'Comparison'">
            <select :disabled="ShouldDisable && ExtendedFeature.weight == 0" v-bind:id="'ExtendedFeature' + ExtendedFeature.featureReference" v-model.number="ExtendedFeature.weight">
                <option value="0">Excluded</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </td>
        <td v-else>
            <select v-model.number="IncludedInDataTable">
                <option value="false">Excluded</option>
                <option value="true">Included</option>
            </select>
        </td>
    </tr>
</template>

<script>

    export default {
        name: 'FeatureRow',
        props: ["ExtendedFeature", "AnalysisType", "ShouldDisable"],
        data: function () {
            return {
                CurrentFeature: null
            };
        },
        methods: {
            GetToolTip: function (extendedFeature) {
                if (extendedFeature.subFeatureWeightModel !== "Custom Sub features Priority" || extendedFeature.subFeatureWeightModel === "Custom Sub features Priority") {
                    return extendedFeature.featureDescription;
                }
            },
            SetEqualWeightings: function (ef) {
                ef.subFeatureWeightModel = "Prioritise Sub features Equally";
            },
            SetCustomWeightings: function (ef) {
                ef.subFeatureWeightModel = 'Custom Sub features Priority';
                this.$emit('custom-subfeature-weights-selected', ef);
            },
        },
        computed: {
            IncludedInDataTable: {
                get: function () {
                    return this.ExtendedFeature.includeInDataTable ? "true" : "false";
                },
                set: function (value) {
                    this.ExtendedFeature.includeInDataTable = value === "true";
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
