<template>
    <div>
        <br />
        <fieldset>
            <legend>
                Please select features to benchmark <span v-if="UserDetails.maxFeaturesPerComparison != null">(maximum {{UserDetails.maxFeaturesPerComparison}} features in total) - {{RemainingFeatures()}} remaining</span>
            </legend>
            <div>
                <p>From the features listed below, please select up to five that you would like to include in your benchmarking and prioritise them 1-5 (5 being the highest).</p>
                <p>By default we will equally weight the sub features, however you can custom weight these by clicking the �custom sub features priority� button should you wish.</p>
            </div>
            <table class="analysisStyles">
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th>{{UserSelection.analysisType === "Comparison" ? "Priority" : "Included"}}</th>
                    </tr>
                </thead>
                <tbody>
                    <feature-row :key="extendedFeature.featureReference" v-for="extendedFeature in UserSelection.extendedFeatures" :should-disable="ShouldDisable" v-on:custom-subfeature-weights-selected="customSubfeatureWeightsSelected" :extended-feature="extendedFeature" :analysis-type="UserSelection.analysisType" />
                </tbody>
            </table>
            <div id="performAnalysisBackButtons">
                <fieldset class="navigation bottomMargin50">
                    <button v-on:click.stop="$emit('back')" class="button150">Back</button>
                    <button v-on:click.stop="$emit('feature-selection-completed')" :disabled="!CanProceed()" class="button150">{{ !IsLast ?  "Next" : UserSelection.analysisType === "Comparison" ? "Compare Providers" : "Get Data Tables"}}</button>
                </fieldset>
            </div>
        </fieldset>
        <sub-feature-selection v-if="CurrentFeature !== null" :feature="CurrentFeature" v-on:subfeature-closed="CurrentFeature = null" v-on:cancel-subfeature="CanSubFeature" />
    </div>
</template>

<script>
    import FeatureRow from "./FeatureRow.vue";
    import SubFeatureSelection from "./SubFeatureSelection.vue";
    export default {
        name: 'FeatureSelection',
        props: ["UserSelection", "UserDetails", "CurrentCategory", "IsLast"],
        components: { FeatureRow, SubFeatureSelection },
        data: function () {
            return {
                FeatureCategories: [],
                isDatatable: false,
                CurrentFeature: null
            };
        },
        methods: {
            RemainingFeatures: function () {
                return this.UserDetails.maxFeaturesPerComparison - this.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).length;
            },
            CanProceed: function () {
                return this.UserSelection.extendedFeatures.some(this.UserSelection.analysisType === "Comparison" ? function (ef) { return ef.weight > 0; } : function (ef) { return ef.includeInDataTable > 0; });
            },
            customSubfeatureWeightsSelected: function (ef) {
                this.CurrentFeature = ef;
            },
            CanSubFeature: function () {
                this.CurrentFeature.subFeatureWeightModel = "Prioritise Sub features Equally";
                this.CurrentFeature = null;
            }
        },
        created: function () {
        },
        computed: {
            ShouldDisable: function ()
            {
                return this.UserDetails.maxFeaturesPerComparison <= this.UserSelection.extendedFeatures.filter(function (ef) { return ef.weight > 0; }).length;
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
