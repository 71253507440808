<template>
    <div class="ModalBackground" style="display:block">
        <div class="ModalBackGroundPanel">
            <div class="header" style="background:none;">
                <h1 class="Title">{{Feature.featureName}}</h1>
                <div class="Options">
                    <button v-on:click.stop="$emit('subfeature-closed')" v-if="CanClose()" class="button150">Close</button>
                    <button v-on:click.stop="$emit('cancel-subfeature')" v-if="!CanClose()" class="button150">Cancel</button>
                </div>
            </div>
            <table class="analysisStyles" style="width:100%">
                <thead>
                    <tr>
                        <th>Subfeature</th>
                        <th>Priority</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="subfeature in Feature.subFeatures" :key="subfeature.subfeatureName">
                        <td>
                            {{subfeature.subfeatureName}}
                        </td>
                        <td>
                            <select v-model.number="subfeature.weight">
                                <option value="0">Excluded</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SubFeatureSelection',
        props: ["Feature"],
        data: function () {
            return {
            }
        },
        methods: {
            CanClose: function () {
                return this.Feature.subFeatures.some(function (ef) { return ef.weight > 0 })
            },

        },
        created: function () {
        },
        computed: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
