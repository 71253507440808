<template>
    <div style="max-width:500px;">
        <div style="width:100%;position:relative;padding:0;padding-bottom:100%">
            <h2>{{Title}}</h2>
            <svg style="width:80%;height:80%;position:absolute; top:10%; left:10%" viewBox="-1 -1 2 2" v-if="ValidData.length > 1">
                <g v-for="(dataItem, index) in ValidData" :key="index">
                    <path :d="GetPath(index)" :fill="GetColor(index)" />
                    <text text-anchor="middle"
                          dominant-baseline="middle"
                          :x="GetPieLegendLocation(index).X"
                          :y="GetPieLegendLocation(index).Y"
                          font-family="Arial"
                          :font-size="FontSize"
                          fill="white">
                        {{(dataItem.Value / Total * 100.0).toFixed(1)*1}}%
                    </text>
                </g>
            </svg>
            <svg style="width:80%;height:80%;position:absolute; top:10%; left:10%" viewBox="-1 -1 2 2" v-if="ValidData.length == 1">
                <g>
                    <circle cx="0" cy="0" r="1" :fill="GetColor(0)" />
                    <text text-anchor="middle"
                          dominant-baseline="middle"
                          x="0"
                          y="0"
                          font-family="Arial"
                          :font-size="FontSize"
                          fill="white">
                        100%
                    </text>
                </g>
            </svg>
        </div><table>
            <tr>
                <td width="50%"></td>
                <td nowrap>
                    <p v-for="(dataItem, index) in ValidData" :key="index">
                        <span class="PieBullet" :style="'color:' + GetColor(index)">&bull;</span> {{dataItem.Legend}}
                    </p>
                </td>
                <td width="50%"></td>
            </tr>
        </table>
    </div>
</template>

<script>
    import helper from './HelperMethods.vue';

    export default {
        name: 'PieChart',
        props: ["ChartData", "Title"],
        data: function () {
            return {
                PieRotation: - Math.PI / 2.0,
                FontSize: 0.1,
                LegendFactor: 0.8,/* the factor of the radius for which to place the value in the pie*/
                minValueForDisplay: 0.03, /* anything less than 3% will be put in 'other'...*/
            }
        },
        methods: {
            GetColor: function (index) {
                var dataItem = this.ValidData[index];
                if (dataItem.Color !== null && dataItem.Color !== "" && typeof (dataItem.Color) !== "undefined")
                    return dataItem.Color;
                return helper.standardChartColors[index % helper.standardChartColors.length]
            },
            GetPath: function (index) {
                var total = this.Total;
                var value = this.ValidData[index].Value;
                var from = this.ValidData.filter(function (value, i) { return i < index; }).reduce(function (sofar, dataItem) { return sofar + dataItem.Value; }, 0);
                return "M " + (Math.cos(from / total * 2.0 * Math.PI + this.PieRotation)) + " " + (Math.sin(from / total * 2.0 * Math.PI + this.PieRotation))
                    + " A 1 1 0 " + (value / total > 0.5 ? "1" : "0") + " 1 " + (Math.cos((from + value) / total * 2.0 * Math.PI + this.PieRotation)) + " " + (Math.sin((from + value) / total * 2.0 * Math.PI + this.PieRotation))
                    + " L 0 0 Z";
            },
            GetPieLegendLocation(index) {
                var total = this.ValidData.reduce(function (sofar, dataItem) { return sofar + dataItem.Value; }, 0);
                var value = this.ValidData[index].Value;
                var from = this.ValidData.filter(function (value, i) { return i < index; }).reduce(function (sofar, dataItem) { return sofar + dataItem.Value; }, 0);
                return {
                    X: this.LegendFactor * Math.cos((from + 0.5 * value) / total * 2.0 * Math.PI + this.PieRotation),
                    Y: this.LegendFactor * Math.sin((from + 0.5 * value) / total * 2.0 * Math.PI + this.PieRotation)
                }
            }
        },
        created: function () {

        },
        computed: {
            Total: function () {
                return this.ChartData.reduce(function (sofar, dataItem) { return sofar + dataItem.Value; }, 0);
            },
            ValidData: {
                get: function () {
                    var thisControl = this;
                    var total = this.Total;
                    var result = this.ChartData.filter(function (dataItem) { return dataItem.Value > 0; })
                    var other = {
                        Color: "#aaa",
                        Legend: "Other",
                        Value: result.filter(function (dataItem) { return dataItem.Value / total < thisControl.minValueForDisplay; }).reduce(function (sofar, dataItem) { return sofar + dataItem.Value; }, 0)
                    };
                    if (other.Value > 0) {
                        result = result.filter(function (dataItem) { return dataItem.Value / total >= thisControl.minValueForDisplay; });
                        result.push(other);
                    }
                    return result;
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
