<template>
    <div>
        <div class="header" style="background:none">
            <h1 class="Title">Privacy Policy</h1>
            <div class="Options">
                <button v-on:click.stop="$emit('back')" class="button150">Close</button>
            </div>
        </div>
        <fieldset>
            <div style="margin-top:10px;">
                <b>BACKGROUND:</b><p></p>
                <p>We understand that your privacy is important to you and that you care about how your information is used and shared online. We respect and value the privacy of everyone who visits Our Site and will only collect and use information in ways that are useful to you and in a manner consistent with your rights and Our obligations under the law.</p>
                <p>This Policy applies to Our use of any and all data collected by us in relation to your use of Our Site. Please read this Privacy Policy carefully and ensure that you understand it. You will be required to read and accept this Privacy Policy when signing up for an Account. If you do not accept and agree with this Privacy Policy, you must stop using Our Site immediately.</p>
                <p>&nbsp;</p>
                <p><b>1. Definitions and Interpretation</b></p>
                <p>In this Policy the following terms shall have the following meanings:</p>
                <p>�Account�</p>
                <p>means an account required to access and/or use certain areas and features of Our Site;</p>
                <p>�Cookie�</p>
                <p>means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in section 12, below;</p>
                <p>�Our Site�</p>
                <p>means this website, <a target="_blank" href="https://benefitsgurupro.co.uk/" style="color: rgb(5,99,193); text-decoration: underline;" class="">www.benefitsgurupro.co.uk</a>;</p>
                <p>�Cookie Law�</p>
                <p>means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;</p>
                <p>�personal data�</p>
                <p>Means any and all data that relates to an identifiable person who can be identified from the data either directly or indirectly. In this case, it means personal data that you give Us via Our Site. This definition shall, where applicable, incorporate the definitions provided in the EU Regulation 2016/679 � The General Data Protection Regulation (�GDPR�); and</p>
                <p>�We/Us/Our�</p>
                <p>means The Financial Technology Research Centre, a limited company registered in England under 02484495, whose registered address is Finsgate, 5-7 Cranwood Street, London, EC1V 9EE and whose main trading address is United House, North Road, London N7 9DP.</p>
                <p>&nbsp;</p>
                <p><b>2. Information About Us</b></p>
                <p>2.1 Our Site, is owned and operated Us. Our VAT number is 673 2700 41.</p>
                <p>2.2 Our Data Protection Officer can be contacted by writing to us or by calling us on our main telephone number.</p>
                <p>&nbsp;</p>
                <p><b>3. Scope � What Does This Policy Cover?</b></p>
                <p>This Privacy Policy applies only to your use of Our Site.</p>
                <p>Our Site may contain links to other websites. Please note that We have no control over how your data is collected, stored, or used by other websites and We advise you to check the privacy policies of any such websites before providing any data to them.</p>
                <p><b>4. Your Rights</b></p>
                <p>4.1 As a data subject, you have the following rights under the GDPR, which this Policy and Our use of personal data have been designed to uphold:</p>
                <p>4.1.1 The right to be informed about Our collection and use of personal data;</p>
                <p>4.1.2 The right of access to the personal data We hold about you (see section 12);</p>
                <p>4.1.3 The right to rectification if any personal data We hold about you is inaccurate or incomplete (please contact Us using the details in section 14);</p>
                <p>4.1.4 The right to be forgotten � i.e. the right to ask Us to delete any personal data We hold about you (We only hold your personal data for a limited time, as explained in section 6 but if you would like Us to delete it sooner, please contact Us using the details in section 14);</p>
                <p>4.1.5 The right to restrict (i.e. prevent) the processing of your personal data;</p>
                <p>4.1.6 The right to data portability (obtaining a copy of your personal data to re-use with another service or organisation);</p>
                <p>4.1.7 The right to object to Us using your personal data for particular purposes; and</p>
                <p>4.1.8 Rights with respect to automated decision making and profiling.</p>
                <p>4.2 If you have any cause for complaint about Our use of your personal data, please contact Us using the details provided in section 14 and We will do Our best to solve the problem for you. If We are unable to help, you also have the right to lodge a complaint with the UK�s supervisory authority, the Information Commissioner�s Office.</p>
                <p>4.3 For further information about your rights, please contact the Information Commissioner�s Office.</p>
                <p><b>5. What Data Do We Collect?</b></p>
                <p>Some data will be collected automatically by Our Site (for further details, please see section 13 on Our use of Cookies), other data will only be collected if you voluntarily submit it, for example, when signing up for an Account. Depending upon your use of Our Site, We may collect some or all of the following data:</p>
                <p>5.1 IP address (automatically collected);</p>
                <p>5.2 web browser type and version (automatically collected);</p>
                <p>5.3 operating system (automatically collected);</p>
                <p>5.4 a list of URLs starting with a referring site, your activity on Our Site, and the site you exit to (automatically collected);</p>
                <p><b>6. How Do We Use Your Data?</b></p>
                <p>6.1 All personal data is stored securely for no longer than is necessary in light of the reason(s) for which it was first collected. We will comply with Our obligations and safeguard your rights under the GDPR. For more details on security see section 7, below.</p>
                <p>6.2 Our use of your personal data will always have a lawful basis, either because it is necessary for Our performance of a contract with you, because you have consented to Our use of your personal data (e.g. by subscribing to emails), or because it is in Our legitimate interests. Specifically, We may use your data for the following purposes:</p>
                <p>6.2.1 Providing and managing your access to Our Site;</p>
                <p>6.2.2 Personalising and tailoring your experience on Our Site;</p>
                <p>6.2.3 Supplying Our services to you;</p>
                <p>6.2.4 Personalising and tailoring Our services for you;</p>
                <p>6.2.5 Responding to emails from you;</p>
                <p>6.2.6 Supplying you with emails that you have opted into (you may unsubscribe or opt-out at any time by clicking on the unsubscribe option at the bottom of emails from Us);</p>
                <p>6.2.7 Analysing your use of Our Site and gathering feedback to enable Us to continually improve Our Site and your user experience;</p>
                <p>6.3 With your permission and/or where permitted by law, We may also use your data for marketing purposes which may include contacting you by email with information, news and offers on Our products or services. We will not, however, send you any unsolicited marketing or spam and will take all reasonable steps to ensure that We fully protect your rights and comply with Our obligations under the GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003, as amended in 2004, 2011 and 2015.</p>
                <p>6.4 You have the right to withdraw your consent to Us using your personal data at any time, and to request that We delete it.</p>
                <p>6.5 We do not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Data will therefore be retained for the following periods (or its retention will be determined on the following bases):</p>
                <p>6.6 Personal data shall be retained for as long as you are a registered user of our website.</p>
                <p><b>7. How and Where Do We Store Your Data?</b></p>
                <p>7.1 We only keep your personal data for as long as We need to in order to use it as described above in section 6, and/or for as long as We have your permission to keep it.</p>
                <p>7.2 Some or all of your data may be stored or transferred outside of the European Economic Area (�the EEA�) (The EEA consists of all EU member states, plus Norway, Iceland and Liechtenstein). You are deemed to accept and agree to this by using Our Site and submitting information to Us. If We do store data outside the EEA, We will take all reasonable steps to ensure that your data is treated as safely and securely as it would be within the UK and under the GDPR</p>
                <p>7.3 Data security is of great importance to Us, and to protect your data We have put in place suitable physical, electronic and managerial procedures to safeguard and secure data collected through Our Site.</p>
                <p>7.4 Notwithstanding the security measures that We take, it is important to remember that the transmission of data via the internet may not be completely secure and that you are advised to take suitable precautions when transmitting to Us data via the internet.</p>
                <p><b>8. Do We Share Your Data?</b></p>
                <p>8.1 We may compile statistics about the use of Our Site including data on traffic, usage patterns, user numbers, sales and other information. All such data will be anonymised and will not include any personally identifying information. We may from time to time share such data with third parties such as prospective investors, affiliates, partners and advertisers. Data will only be shared and used within the bounds of the law.</p>
                <p>8.2 In certain circumstances, We may be legally required to share certain data held by Us, which may include your personal data, for example, where We are involved in legal proceedings, where We are complying with legal requirements, a court order, or a governmental authority.</p>
                <p><b>9. What Happens If Our Business Changes Hands?</b></p>
                <p>9.1 We may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of control of all or part of Our business. Any personal data that you have provided will, where it is relevant to any part of Our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the data for the purposes for which it was originally collected by Us.</p>
                <p>9.2 In the event that any of your data is to be transferred in such a manner, you will be contacted in advance and informed of the changes.</p>
                <p><b>10. How Can You Control Your Data?</b></p>
                <p>10.1 In addition to your rights under the GDPR, set out in section 4, when you submit personal data via Our Site, you may be given options to restrict Our use of your data. In particular, We aim to give you strong controls on Our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from Us which you may do by unsubscribing using the links provided in Our emails and at the point of providing your details and by managing your Account).</p>
                <p>10.2 You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone Preference Service (�the TPS�), the Corporate Telephone Preference Service (�the CTPS�), and the Mailing Preference Service (�the MPS�). These may help to prevent you receiving unsolicited marketing. Please note, however, that these services will not prevent you from receiving marketing communications that you have consented to receiving.</p>
                <p><b>11. Your Right to Withhold Information</b></p>
                <p>11.1 You may access certain areas of Our Site without providing any data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.</p>
                <p>11.2 You may restrict your internet browser�s use of Cookies. For more information, see section 13 below.</p>
                <p>&nbsp;</p>
                <p><b>12. How Can You Access Your Data?</b></p>
                <p>You have the legal right to ask for a copy of any of your personal data held by Us (where such data is held).</p>
                <p>Under the GDPR, no fee is payable and We will provide any and all information in response to your request free of charge. Please contact Us for more details at info@ftrc.co.uk, or using the contact details below in section 14. Alternatively, please refer to Our Data Protection Policy https://benefitsguru.co.uk/data-protection/</p>
                <p><b>13. What Cookies Do We Use and What For?</b></p>
                <p>13.1 Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and services. For more details, please refer to section 5, above, and to section 12.6 below. We have carefully chosen these Cookies and have taken steps to ensure that your privacy is protected and respected at all times.</p>
                <p>13.2 By using Our Site you may also receive certain third party Cookies on your computer or device. Third party Cookies are those placed by websites, services, and/or parties other than Us. We use third party Cookies on Our Site for analysis of web page traffic to improve our website in order to tailor it to customer needs. For more details, please refer to section 6, above, and to section 13.6 below. These Cookies are not integral to the functioning of Our Site.</p>
                <p>13.3 All Cookies used by and on Our Site are used in accordance with current Cookie Law.</p>
                <p>13.4 Before Cookies are placed on your computer or device, you will be shown details of them and you will be requested to consent to placing of those Cookies. By giving your consent to the placing of Cookies you are enabling Us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of Our Site may not function fully or as intended.</p>
                <p>13.5 Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be �strictly necessary�. These Cookies are shown below in section 13.6. Your consent will not be sought to place these Cookies. You may still block these Cookies by changing your internet browser�s settings as detailed below in section 13.10, but please be aware that Our Site may not work as intended if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them.</p>
                <p>13.6 The following first party Cookies may be placed on your computer or device:</p>
                <p>
                    
                </p><table>
                    
                    <thead>
                        
                        <tr>
                            
                            <th>Name of Cookie:</th>
                            <th>Purpose:</th>
                            <th>Strictly Necessary:</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            
                            <td>ga_disable</td>
                            <td>Manage cookie preferences</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
                <!-- #tablepress-1 from cache --><p></p>
                <p>and the following third party Cookies may be placed on your computer or device:</p>
                <p>
                    
                </p><table>
                    
                    <thead>
                        
                        <tr>
                            
                            <th>Name of Cookie:</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            
                            <td>_ga</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_gid</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_umta</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtb</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtc</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtz</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_umtv</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                    </tbody>
                </table>
                <!-- #tablepress-2 from cache --><p></p>
                <p>13.7 Our Site uses analytics services provided by Google Analytics (GA). GA analytics refers to a set of tools used to collect and analyse usage statistics, enabling Us to better understand how people use Our Site. This, in turn, enables Us to improve Our Site and the services offered through it. You do not have to allow Us to use these Cookies, as detailed below, however whilst Our use of them does not pose any risk to your privacy or your safe use of Our Site, it does enable Us to continually improve Our Site, making it a better and more useful experience for you.</p>
                <p>13.8 The analytics service(s) used by Our Site use(s) Cookies to gather the required information</p>
                <p>13.9 The analytics service(s) used by this Website use(s) the following Third Party Cookies:</p>
                <p>
                    
                </p><table>
                    
                    <thead>
                        
                        <tr>
                            
                            <th>Name of Cookie:</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            
                            <td>_ga</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_gid</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_umta</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtb</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtc</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>umtz</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                        <tr>
                            
                            <td>_umtv</td>
                            <td>Google Analytics</td>
                            <td>Analytics</td>
                        </tr>
                    </tbody>
                </table>
                <p></p>
                <p>13.10 You can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all cookies or only third party cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</p>
                <p>13.11 You can choose to delete Cookies at any time however you may lose any information that enables you to access Our Site more quickly and efficiently including, but not limited to, login and personalisation settings.</p>
                <p>13.12 It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.</p>
                <p><b>14. Contacting Us</b></p>
                <p>If you have any questions about Our Site or this Privacy Policy, please contact Us by email at info@ftrc.co.uk, by telephone on 020 3740 0000, or by post at FTRC Limited, United House, North Road, London N7 9DP. Please ensure that your query is clear, particularly if it is a request for information about the data We hold about you (as under section 12, above).</p>
                <p><b>15. Changes to Our Privacy Policy</b></p>
                <p>We may change this Privacy Policy as we may deem necessary from time to time, or as may be required by law. Any changes will be immediately posted on Our Site and you will be required to read and accept the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date.</p>
            </div>
        </fieldset>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicy',
        methods: {
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
