<template>
    <div>
        <div style="padding-bottom:58px;">
            <div v-if="Selection === null">
                <loading-control :loading-message="'Loading Tools'" />
            </div>
            <div>
                <tool-selection v-if="Selection !== null && Screen == 'Tool Selection'" v-on:tool-selected="toolSelected" v-bind:user-selection="Selection" />
            </div>
            <div>
                <provider-selection v-if="Screen == 'Provider Selection'" v-on:back="back" v-on:provider-selection-completed="providerSelectionCompleted()" v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
            </div>
            <div>
                <feature-selection v-if="Screen == 'Feature Selection'" v-on:back="back" :is-last="IsLast()" v-bind:user-selection="Selection" v-bind:user-details="UserDetails" v-on:feature-selection-completed="featureSelectionCompleted" />
            </div>
            <div>
                <analysis v-if="Screen == 'Analysis'" v-on:back="back" v-bind:user-selection="Selection" v-bind:user-details="UserDetails" />
            </div>
            <div>
                <terms-conditions v-if="Screen == 'Terms and Conditions'" v-on:back="back" />
            </div>
            <div>
                <privacy-policy v-if="Screen == 'Privacy Policy'" v-on:back="back" />
            </div>
        </div>
        <div>
            <footer-control v-if="UserDetails !== null" :current-screen="Screen" v-on:privacy="showPrivacyPolicy" v-on:tac="showTermsAndConditions" />
        </div>
    </div>
</template>

<script>
    import LoadingControl from './LoadingControl.vue'
    import ToolSelection from "./ToolSelection.vue"
    import ProviderSelection from "./ProviderSelection.vue"
    import FeatureSelection from "./FeatureSelection.vue"
    import Analysis from "./Analysis.vue"
    import FooterControl from "./Footer.vue"
    import TermsConditions from "./TermsConditions.vue"
    import PrivacyPolicy from "./PrivacyPolicy.vue"
    import $ from 'jquery';
    import helper from './HelperMethods.vue';

    export default {
        name: 'NavigationControl',
        props: ["UserDetails"],
        data: function () {
            return {
                Selection: null,
                Screen: "Tool Selection"
            };
        },
        components: {
            LoadingControl,
            ToolSelection,
            ProviderSelection,
            FeatureSelection,
            Analysis,
            FooterControl,
            TermsConditions,
            PrivacyPolicy
        },
        created: function () {
            var thisControl = this;
            $.ajax({
                type: 'GET',
                url: 'Api/config',
                contentType: 'application/json',
                success: function (Selection) {
                    thisControl.Selection = Selection;
                    history.replaceState({ screen: "Provider Selection", type: "screen" }, "Provider Selection");
                    document.title = "Benefits Guru Pro - " + "Provider Selection";
                },
                error: function (xhr) {
                    //alert("Error Loading Benefits Guru: " + xhr.responseText);
                    thisControl.$gtag.exception({
                        'description': 'Error getting tool details (' + xhr.status + ').',
                        'fatal': true
                    });
                }
            });
            window.addEventListener("popstate", function (event) {
                thisControl.goToPage(event.state);
            });
        },
        methods: {
            toolSelected: function (toolReference) {
                this.Selection.tool = this.Selection.tools.filter(function (t) { return t.toolReference === toolReference; })[0];
                this.Selection.providers = this.Selection.providersTool[toolReference];
                this.Selection.features = this.Selection.featuresTool[toolReference];
                this.Selection.extendedFeatures = this.Selection.extendedFeaturesTool[toolReference];
                this.Selection.selectedProviders = []; //clear providers
                this.Selection.categoryWeightings = this.Selection.extendedFeatures
                    .sort(function (ef1, ef2) { return ef1.featureCategoryOrder - ef2.featureCategoryOrder; })
                    .map(function (ef) { return ef.featureCategory; })
                    .filter(helper.distinct)
                    .map(function (c) { return { category: c, weight: 0, includedInDataTable: 0 }; });

                if (this.Selection.tool.hasDataTables && this.UserDetails.hasAccessToDataTables)
                    this.LoadPage("Report Selection");
                else {
                    this.Selection.analysisType = "Comparison";
                    this.LoadPage("Provider Selection");
                }
            },
            dataTableSelected: function () {
                this.Selection.analysisType = "DataTables";
                this.LoadPage("Provider Selection");
            },
            comparisonSelected: function () {
                this.Selection.analysisType = "Comparison";
                this.LoadPage("Provider Selection");
            },
            policyDetailsAdded: function () {
                this.LoadPage("Provider Selection");
            },
            back: function () {
                window.history.back();
            },
            LoadPage: function (screen) {
                this.Screen = screen;
                this.track('/' + this.Screen);
                history.pushState({ screen: screen, type: "screen" }, screen);
                document.title = "Benefits Guru Pro - " + screen;
            },
            goToPage: function (screendetails) {
                switch (screendetails.type) {
                    case "screen":
                        this.Screen = screendetails.screen;
                        this.track('/' + this.Screen);
                        document.title = "Benefits Guru Pro - " + this.Screen;
                        break;
                    case "featureCategory":
                        this.Screen = "Feature Selection";
                        this.track('/' + this.Screen);
                        document.title = "Benefits Guru Pro - " + this.Screen;
                        break;
                }

            },
            providerSelectionCompleted: function () {
                this.LoadPage("Feature Selection");
            },
            featureSelectionCompleted: function () {
                this.LoadPage("Analysis");
            },
            showPrivacyPolicy: function () {
                this.LoadPage("Privacy Policy");
            },
            showTermsAndConditions: function () {
                this.LoadPage("Terms and Conditions");
            },
            IsLast: function () {
                return true;
            },
            track: function (page_path) {
                this.$gtag.pageview({
                    'page_path': page_path,
                });
            }
        },
    }
</script>