<template>
    <div>
        <div>
            <p>Benefits Guru Pro is our new API, mobile enabled comparison tool, designed for employers and advisers to compare their current pension provider against others in the market.  The tool allows you to benchmark your current pension proposition anonymously against 19 other propositions. </p>
            <p>From the drop down, please select who your current pension provider is and hit the �Next� button. If your current pension provider is not showing, please contact a member of the Benefits Guru team to discuss. We will be adding a further detailed version of this tool, which will include a full provider selection and governance report in the coming months.</p>
            <select v-model="UserSelection.selectedProviders" v-on:change="providerChange()">
                <option v-for="provider in UserSelection.providers" :disabled="IsDisabled(provider.providerReference)" v-bind:key="provider.providerReference" v-bind:value="provider.providerReference">{{ provider.providerName }}</option>
            </select>
            <br v-if="isInsurersHidden" />
        </div>
        <div class="navigation bottomMargin50">
            <button class="button250" v-if="false" v-on:click.stop="$emit('back')">Back</button>
            <button :disabled="UserSelection.selectedProviders.length === 0" class="button250" v-on:click.stop="$emit('provider-selection-completed')">Next</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProviderSelection',
        props: ["UserSelection", "UserDetails"],
        data: function () {
            this.UserSelection.selectedProviders = [];

            return {
                isInsurersHidden: true
            };
        },
        methods:
        {
            SelectAllProviders: function () {
                this.UserSelection.selectedProviders = this.UserSelection.providers.map(function (provider) { return provider.providerReference; });
            },
            DeselectAllProviders: function () {
                this.UserSelection.selectedProviders = [];
            },
            RemainingProviders: function () {
                return this.UserDetails.maxProvidersPerComparison - this.UserSelection.selectedProviders.length;
            },
            IsDisabled: function (providerID) {
                return this.UserDetails.maxProvidersPerComparison !== null && (this.UserDetails.maxProvidersPerComparison - this.UserSelection.selectedProviders.length) <= 0 && !this.UserSelection.selectedProviders.some(function (id) { return id === providerID; });
            },
            providerChange: function () {
                if (this.UserSelection.extendedFeatures !== null) {
                    this.UserSelection.extendedFeatures.forEach(function (feature) {
                        feature.weight = 0;
                    });
                }
            }
        },
        created: function () {
            this.isInsurersHidden = this.UserDetails.maxProvidersPerComparison === null && this.UserSelection.selectedProviders.length === 0;
        },
        computed: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
