import Vue from 'vue'
import App from './MainApp.vue'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: {
        id: "G-GLQRKT4634",
        params: {
            send_page_view: false
        }
    }

});

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#MainPage')
