<template>
    <div id="toolSelection" class="initialSelectionForm">
        <label>
            What Type of Analysis are you wanting to perform:
        </label>
        <div style="display:inline-grid;">
            <button v-for="tool in UserSelection.tools" v-bind:key="tool.toolName" v-on:click.stop="SelectTool(tool.toolReference)">{{tool.toolName}} <br /></button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ToolSelection',
        props: ["UserSelection"],
        data: function () {
            return {
                Selection: null,
                Screen: "Provider Selection"
            };
        },
        created: function () {
            this.$emit("tool-selected", "BGP MVP");
        },
        methods: {
            SelectTool: function (toolReference) {
                this.$emit("tool-selected", toolReference);
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
